import {ChangeEvent, FormEvent, useEffect, useState } from "react";
import React from 'react';
import {
  VuiSearchInput,
  VuiIcon,
  VuiText,
  VuiTextColor,
  VuiFlexContainer,
  VuiFlexItem
} from "@adventera/vectara-ui";
import "./searchControls.scss";
import { useConfigContext } from "@services/context/ConfigurationContext";
import { useSearchContext } from "@services/context/SearchContext";
import { SUMMARY_LANGUAGES, SummaryLanguage, humanizeLanguage, SearchControlsProps } from "@customTypes/search";
import { VuiButtonEmpty } from "@ui/components/button/ButtonEmpty";
import { BiTimeFive } from "react-icons/bi";
import { Dropdown} from 'semantic-ui-react'
import { Badge } from "@ui/components/badge/Badge";
export const SearchControls = ({
  isHistoryOpen,
  onToggleHistory,
  hasQuery,
} : SearchControlsProps) => {

  // WITH SOURCE
  const {filterValue, searchValue,setSearchValue,onSearch,language} = useSearchContext();

  // WITH CORPUS
  // const {corpusNameValue, searchValue,setSearchValue,onSearch,language} = useSearchContext();

  useEffect(() => {
    setLang(language)
  },[language]);

  // WITH SOURCE
  const { summary, filters} = useConfigContext();
  // WITH CORPUS
  // const { summary, authConfig} = useConfigContext();

  const defaultLanguage = summary.defaultLanguage as unknown as SummaryLanguage;
  const [lang, setLang] = useState<SummaryLanguage>(language);
  const [detectedLanguagesWithTotalScores, setDetectedLanguagesWithTotalScores] = useState<Record<string, number>>();
  var languageOptions = SUMMARY_LANGUAGES.map((code) => ({
    key: code,
    value: code,
    text: humanizeLanguage(code),
  }));
 
  const languageOptionsdefaultElement = languageOptions.filter((option) => option.text === humanizeLanguage(lang as unknown as SummaryLanguage));
  var languageOptionsWithoutDefaultValue = languageOptions.filter((option) => option.text !== humanizeLanguage(lang as unknown as SummaryLanguage));
  languageOptionsWithoutDefaultValue.sort((a, b) => (a.text < b.text ? -1 : a.text > b.text ? 1 : 0)); //Tri par ordre alphabétique
  languageOptions = languageOptionsdefaultElement.concat(languageOptionsWithoutDefaultValue)

  function isSummaryLanguage(value: any): value is SummaryLanguage {
    return SUMMARY_LANGUAGES.includes(value);
  }

const onSearchChange = (freq : number) => (event: ChangeEvent<HTMLInputElement>) => {

    // Mettre fin à l'animation sur le dropdown s'il y en a une
    const languageItemElement = document.getElementsByClassName("dropdown-item");
    languageItemElement[0]?.addEventListener('animationend', () => {
      languageItemElement[0]?.classList.remove('background-fade');
    });
    
    // Quand on vide la barre de recherche on reprend la langue par défaut, avec animation si différente de la langue actuelle
    if(!event.target.value.trim()){
      setSearchValue(event.target.value);
      if(lang !== defaultLanguage){
        setLang(defaultLanguage);
        languageItemElement[0]?.classList.add("background-fade");
      }
      return
    }


    // Détection des 3 langues avec le + haut score pour l'entrée de l'utilisateur
    const LanguageDetect = require('languagedetect');
    const lngDetector = new LanguageDetect();
    lngDetector.setLanguageType("iso3")
    const threeHighestLanguagesWithScore : Array<[string,number]> = lngDetector.detect(event.target.value).slice(0,3)

    // Complétion de detectedLanguagesWithTotalScores en implémentant les scores pour les trois langues détectées
    if(threeHighestLanguagesWithScore){
      threeHighestLanguagesWithScore.forEach( langWithScore => {
        if(!detectedLanguagesWithTotalScores){
          setDetectedLanguagesWithTotalScores({[langWithScore[0]] : langWithScore[1]})
          return;
        }
        detectedLanguagesWithTotalScores[langWithScore[0]] = 
          langWithScore[0] in detectedLanguagesWithTotalScores 
          ? detectedLanguagesWithTotalScores[langWithScore[0]] + langWithScore[1]
          : langWithScore[1];
        setDetectedLanguagesWithTotalScores(detectedLanguagesWithTotalScores);
      });
    }

    // On évalue le changement de langue à la fréquence freq
    if(event.target.value.length % freq === 0){

      // Détection de la langue au plus haut score
      var langWithMaxScore : string = "";
      var maxValue : number = 0;
      for (const detectedLang in detectedLanguagesWithTotalScores) {
        if (detectedLanguagesWithTotalScores[detectedLang] > maxValue) {
          maxValue = detectedLanguagesWithTotalScores[detectedLang];
          langWithMaxScore = detectedLang;
        }
      }

      // Changement de langue si la langue est bien prise en charge 
      if(langWithMaxScore !== lang && isSummaryLanguage(langWithMaxScore)){
        setLang(langWithMaxScore)
        languageItemElement[0]?.classList.add("background-fade")
      }

      setDetectedLanguagesWithTotalScores({});
    }
    setSearchValue(event.target.value);
  };

  const onSearchSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch({ value: searchValue, language : lang as unknown as SummaryLanguage});
  };

  // Filtres pour les sources par corpus
  // WITH SOURCE
  const filtersValues : Array<{ value: string; text: string }> = [];
  if(filters.isEnabled){
    filtersValues.push({
      text: "Toutes les sources",
      value: ""
    });

    filters.labelsWitValues.forEach(({label, value})=> {
      filtersValues.push({ text: label, value });
    });
  }
  // WITH CORPUS
  // const corpusNamesOptions : Array<{ value: string; text: string }> = [];
  // if(authConfig.multipleCorpus){
  //   corpusNamesOptions.push({
  //     text: "Toutes les sources",
  //     value: ""
  //   });

  //   authConfig.labelsWitValues.forEach(({label, value})=> {
  //     corpusNamesOptions.push({ text: label, value });
  //   });
  // }


  const handleListItemClick = (value: SummaryLanguage) => {
    setLang(value);
    onSearch({
      language: value,
    });
  };
    

  return (
    <VuiFlexContainer 
      direction="column"
      className="searchControls"
      justifyContent="spaceBetween"
      >

      <VuiSearchInput
        size="l"
        value={searchValue}
        onChange={onSearchChange(7)}
        onSubmit={onSearchSubmit}
        placeholder={""}
        autoFocus
        className="searchInput"
      />

      {/* WITH CORPUS */}
      {/* {authConfig.multipleCorpus && ( */}
      {/* WITH SOURCE */}
      {filters.isEnabled && (
        <VuiFlexContainer direction="row" alignItems="center" justifyContent="end" className="searchParams" spacing="none">
          {/* PARTIE POUR LES FILTRES DE SOURCE */}
          <VuiFlexContainer
              alignItems="center"
              className="filtersBar"
              justifyContent="start"
              spacing="none"
            >
              <VuiFlexItem>
                <legend>
                  <VuiText>
                    <VuiTextColor color="subdued">
                      <p>Filtrer par source</p>
                    </VuiTextColor>
                  </VuiText>
                </legend>
              </VuiFlexItem>

                {/* WITH CORPUS */}
                {/* {corpusNamesOptions.map((option) => {
                  const isSelected = option.value === corpusNameValue; */}
                {/* WITH SOURCE */}
                {filtersValues.map((option) => {
                  const isSelected = option.value === filterValue;
                  return (
                    <VuiFlexItem key={option.value}>
                      <Badge 
                        text={option.text} 
                        textType="source"  
                        onClick={() =>
                          // WITH SOURCE
                          onSearch({
                            filter: isSelected ? "" : option.value,
                          })
                          // WITH CORPUS
                          // onSearch({
                          //   corpusName: isSelected ? "" : option.value,
                          // })
                        }
                        // SOURCE_CHOICE
                        color={isSelected ? "green" : "normal"} 
                        size="xs"/>
                    </VuiFlexItem>
                  );
                })}
              {/* </VuiFlexContainer> */}
          </VuiFlexContainer>
          

          <VuiFlexContainer justifyContent="end" className="langHistory" spacing="none">
            <VuiFlexItem className="dropdownLangues">
              <Dropdown
                className="dropdown-item"
                placeholder={
                  lang ? humanizeLanguage(lang as unknown as SummaryLanguage): humanizeLanguage(defaultLanguage as unknown as SummaryLanguage)
                }
                fluid
                selection
                options={languageOptions}
                onChange={(event, data) => handleListItemClick(data.value as unknown as SummaryLanguage)}
                value = {lang   ? lang  : defaultLanguage}
                />
                
            </VuiFlexItem>
            <VuiFlexItem className="historique">
              <VuiButtonEmpty
                color="normal"
                size="s"
                isPressed={isHistoryOpen}
                onClick={onToggleHistory}
                icon={
                  <VuiIcon size="m">
                    <BiTimeFive />
                  </VuiIcon>
                }
              >
                <p>Historique</p>
              </VuiButtonEmpty>
            </VuiFlexItem>
          </VuiFlexContainer>
          
        </VuiFlexContainer>
      )}

      {/* WITH CORPUS */}
      {/* {!authConfig.multipleCorpus &&( */}
      {/* WITH SOURCE */}
      {!filters.isEnabled &&(
        <VuiFlexContainer alignItems="stretch" justifyContent="end">
          <VuiFlexItem>
            <Dropdown
              className="dropdown-item"
              placeholder={
                lang ? humanizeLanguage(lang as unknown as SummaryLanguage): humanizeLanguage(defaultLanguage as unknown as SummaryLanguage)
              }
              fluid
              selection
              options={languageOptions}
              onChange={(event, data) => handleListItemClick(data.value as unknown as SummaryLanguage)}
              value = {lang   ? lang  : defaultLanguage}
              />
              
          </VuiFlexItem>
          <VuiFlexItem>
            <VuiButtonEmpty
              color="normal"
              size="s"
              isPressed={isHistoryOpen}
              onClick={onToggleHistory}
              icon={
                <VuiIcon size="m">
                  <BiTimeFive />
                </VuiIcon>
              }
            >
              Historique
            </VuiButtonEmpty>
          </VuiFlexItem>
      </VuiFlexContainer>
      )}
    </VuiFlexContainer>
  );
};
