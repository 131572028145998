import * as React from 'react';
import {Badge} from "@ui/components/badge/Badge";
import { SummaryTextWithContentsProps } from '@customTypes/search';
import { useConfigContext } from '@services/context/ConfigurationContext';


const TextWithContents = ({ allText, allContent}: SummaryTextWithContentsProps) => {
  const config = useConfigContext();
  const END_REF_TAG = config.endRefTag
  allContent = allContent ? allContent.substring(allContent.indexOf(":///") + ":///".length, allContent.length) : ""
  allContent = allContent.substring(0, allContent.length - END_REF_TAG.length)

  return (
    <>
    {
      allContent &&
      // SUMMARY_REF
      <>{allText}<Badge text={allContent.slice(1, -1).trim()} textType="ref" color="green" size="xs" className="vuiSummaryCitation"/>
      </>
    }
    {
      !allContent &&
      <>{allText}</>
    }
    </>
  )
}

export default TextWithContents;
