import { forwardRef} from "react";
import classNames from "classnames";
import { BadgeProps } from "@customTypes/search";

export const Badge = forwardRef<HTMLButtonElement | null, BadgeProps>(
  (
    {
      text, 
      textType,
      onClick,
      color,
      size = "m",
      className,
      isPressed,
      isSelected,
      fullWidth,
      href,
      target,
      track,
      ...rest
    }: BadgeProps,
    ref
  ) => {

    function handleReference(): void {
      const id = `searchResult_${text}`;
      const searchResultElement = document.getElementById(id);
      const appHeaderElement = document.getElementById("appHeader");
      const yOffset = - appHeaderElement?.offsetHeight!; 
      let y;
      if(searchResultElement){
        y = searchResultElement!.getBoundingClientRect().top  + yOffset;
      }
      else {
        const firstElement = document.getElementById(`searchResult_1`);
        y = firstElement!.getBoundingClientRect().top  + yOffset;
      }
      window.scrollTo({top: y, behavior: 'smooth'});
    }


    const classes = textType === "ref" 
      ? classNames(
        className,
        "vuiReference",
        `vuiReference--${color}`,
        `vuiReference--${size}`,
        {
          "vuiReference--fullWidth": fullWidth,
          "vuiReference--isPressed": isPressed,
          "vuiReference--isSelected": isSelected,
        }
      )
      : classNames(className, "vuiBadge", `vuiBadge--${color}`, {
        "vuiBadge--clickable": onClick ?? href,
      });

    const props = {
      className: classes,
      onClick: textType==="ref" ? handleReference : onClick,
      ...rest,
    };


    return (
      <button {...props} ref={ref}>
        {text}
      </button>
    );
  }
);
