import classNames from "classnames";
import { VuiIconProps } from "@customTypes/search";

export const VuiIcon = ({ iconElement, color, size, className }: VuiIconProps) => {
  const classes = classNames(className, "vuiIcon", `vuiIcon--${color}`);
  const IconElement = iconElement as keyof JSX.IntrinsicElements;
  return (
    <div className={classes}>
      <IconElement size={size} />
    </div>
  );
};
