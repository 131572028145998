import classNames from "classnames";
import { TextProps } from "@customTypes/search";

export const VuiText = ({ children, size = "s", align = "left" }: TextProps) => {
  const classes = classNames(
    "vuiText",
    `vuiText--${size}`,
    `vuiText--${align}`
  );
  return <div className={classes}>{children}</div>;
};
