import { ChangeEventHandler, FormEventHandler, ReactElement, ReactNode } from "react";
import { Socket } from "socket.io-client";

// ---------------------------------------
// TYPES PAR DEFAUT DES REPONSES DU SERVER
export type Metadata = {
  name: string;
  value: string;
};

export type SearchResponseDoc = {
  id: string;
  metadata: Metadata[];
};

export type SearchResponseResult = {
  corpusKey: {
    corpusId: string;
    customerId: string;
    dim: string[];
  };
  documentIndex: string;
  resultLength: number;
  resultOffset: number;
  score: number;
  text: string;
  sectionMetadata: Array<Metadata>;
};

export type SearchResponse = {
  document: SearchResponseDoc[];
  response: SearchResponseResult[];
};

// ---------------------------------------

// ---------------------------------------
// TYPES

const INTERFACE_TYPES = [
  "searchEngine",
  "chatbot"
] as const;
export type InterfaceType = (typeof INTERFACE_TYPES)[number];

export type User = { email: string };

export type ConfigProp = keyof Config;

export type Source = { value: string; label: string };

export type SummaryDefaultLanguage = {
  defaultLanguage: string;
};

export type ExampleQuestions = string[];

export type SearchLink = {
  id: string,
  searchTitleInner: string,
  searchTitleUrl: string,
  searchTitlePost: string,
}

export type SearchLinks = SearchLink[];

export type AuthenticationContextProviderProps = {
  children: ReactNode;
}

// WITH SOURCE
export type HistoryItemBase<searchResultsType,summarizationResponse> = {
  query: string;
  language: SummaryLanguage;
  filterValue: string;
  searchResponse? : searchResultsType, 
  summarizationResponse? : summarizationResponse
  date?: string;
  hidden?: boolean;
};

// WITH CORPUS 
// export type HistoryItemBase<searchResultsType,summarizationResponse> = {
//   query: string;
//   language: SummaryLanguage;
//   filterValue: string;
//   corpusNameValue: string;
//   searchResponse? : searchResultsType, 
//   summarizationResponse? : summarizationResponse
//   date?: string;
//   hidden?: boolean;
// };

export type HistoryItem = HistoryItemBase<OrganizedSearchResponse, SummaryType>

export type Filters = {
  isEnabled: boolean;
  labelsWitValues: Source[];
  valueToLabelMap: Record<string, string>;
};

export type AuthConfig = {
  multipleCorpus: boolean;
  labelsWitValues :Source[];
  valueToLabelMap : Record<string, string>;
};

export type SummaryType = {
  texts: string[];
  contents: string[];
}

export type Paragraphe = {
  contents: Array<string>;
  texts: Array<string>;
  inContent: boolean;
}

export type OrganizedSearchResponseResult = {
  corpusKey: {
    corpusId: string;
    customerId: string;
    dim: string[];
  };
  documentIndex: string;
  resultLength: number;
  resultOffset: number;
  score: number;
  paragraphes: Array<Paragraphe>
  sectionMetadata: Array<Metadata>;
};

export type OrganizedSearchResponse = {
  status : string[]
  document: SearchResponseDoc[];
  response: OrganizedSearchResponseResult[];
};

export type PreTextPostString = {
  pre: string,
  text: string,
  post: string,
  [key: string]: string
}
// ---------------------------------------


// ---------------------------------------
// PROPS DES COMPONENTS

export type SearchSummaryProps ={
  isSummarizing: boolean;
  summarizationError?: any;
  summary?: SummaryType;
}

export type SearchControlsProps = {
  isHistoryOpen: boolean;
  onToggleHistory: () => void;
  hasQuery: boolean;
};

const TEXT_TYPES = [
  "ref",
  "source"
] as const;

export type TextType = (typeof TEXT_TYPES)[number];

export type BadgeProps = {
  text: string;
  textType: TextType;
  onClick?: any;
  color: (typeof COLOR)[number];
  size?: (typeof SIZE)[number];
  className?: string;
  isPressed?: boolean;
  isSelected?: boolean;
  fullWidth?: boolean;
  href?: LinkProps["href"];
  target?: LinkProps["target"];
  track?: LinkProps["track"];
}

export type LinkProps = {
  children: ReactNode;
  href: string;
  className?: string;
  target?: "_blank";
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  track?: boolean;
  // ...rest
  title?: string;
  id?: string;
  role?: string;
};

const COLOR = ["accent", "primary", "fullshade","success","danger", "warning", "normal","subdued", "blue", "red", "green", "white"] as const;

const SIZE = ["xxs", "xs", "s", "m", "l", "xl", "xxl"]  as const;

const HEADING_ELEMENT = ["h1", "h2", "h3", "h4", "h5", "h6", "p"];

export type VuiCalloutProps = {
  children?: ReactNode;
  title: string;
  headingElement: typeof HEADING_ELEMENT[number];
  color: typeof COLOR[number];
}

const GROW = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
export type VuiFlexItemProps = {
  children?: ReactNode;
  grow?: typeof GROW[number];
  className?: string;
};

export type VuiIconProps = {
  iconElement: any;
  color: typeof COLOR[number];
  size: number;
  className?: string;
};

export type  VuiLinkProps = {
  children: ReactNode;
  href: string;
  className?: string;
  target?: "_blank";
};

export type VuiButtonProps = {
  children: ReactNode;
  href: string;
  className?: string;
  target?: "_blank";
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  track?: boolean;
  // ...rest
  title?: string;
  id?: string;
  role?: string;
};
export type VuiSearchInputProps = {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  autoFocus?: boolean;
  onSubmit?: FormEventHandler;
};

export interface SpacerProps {
  size: (typeof SIZE)[number];
}

const TEXT_ALIGN = ["left", "center", "right"];
export type TextProps = {
  children?: ReactNode;
  size?: typeof SIZE[number];
  align?: typeof TEXT_ALIGN[number];
};

export type TextColorProps = {
  children?: ReactNode;
  color: typeof COLOR[number];
  className?: string;
};

const TEXT_ALIGN_TITLE = ["left", "center", "right"];
export interface TitleProps {
  children: ReactElement<any>;
  size: typeof SIZE[number];
  align?: typeof TEXT_ALIGN_TITLE[number];
}

export type HistoryDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
};

export type SearchResultsProps = {
  isSearching: boolean;
  searchError?: any;
  results?: OrganizedSearchResponse
};

export type SummaryTextWithContentsProps = {
  allText: string;
  allContent: string;
}

export type SearchContextProviderProps = {
  children: ReactNode;
}

export type SearchResultProps = {
  doc: SearchResponseDoc;
  res: OrganizedSearchResponseResult;
  position: number;
}

export type SearchResultListProps = {
  results: OrganizedSearchResponse;
};

export type AllTextsWithContentsProps = {
  paragraphes: Array<Paragraphe>
  columnNames: Array<Array<string>>;
  textSize?: typeof SIZE[number];
}

export type TextWithContentsProps = {
  allText: string;
  allContent: string;
  inContent: boolean;
  headerColumns: Array<Array<string>>;
}

export type VuiImgContainerProps = {
  content: string;
  alt? : string;
  className?: string;
  onLoad? : any;
}

export type BodyProps = {
  content: string;
  nbColumns: number;
  config : any;
}

export type OrganizedColumn = Array<Paragraphe>;

export type OrganizedRow = OrganizedColumn[]

export type RowsProps = {
  content: string;
  isStrong: boolean;
  nbColumn: number;
  config : any;
}
// ---------------------------------------


// ---------------------------------------
// TYPES POUR LANGAGE

export const SUMMARY_LANGUAGES = [
  "eng",
  "deu",
  "fra",
  "zho",
  "kor",
  "ara",
  "rus",
  "tha",
  "nld",
  "ita",
  "por",
  "spa",
  "jpn",
  "pol",
  "tur",
] as const;

export type SummaryLanguage = (typeof SUMMARY_LANGUAGES)[number];

const codeToLanguageMap: Record<SummaryLanguage, string> = {
  eng: "Anglais",
  deu: "Allemand",
  fra: "Français",
  zho: "Chinois",
  kor: "Coréen",
  ara: "Arabe",
  rus: "Russe",
  tha: "Thailandais",
  nld: "Néerlandais",
  ita: "Italian",
  por: "Portugais",
  spa: "Espagnol",
  jpn: "Japonais",
  pol: "Polonais",
  tur: "Turc",
} as const;

export const humanizeLanguage = (language: SummaryLanguage): string => {
  return codeToLanguageMap[language];
};
// ---------------------------------------


// ---------------------------------------
// INTERFACES

// WITH SOURCE
export interface SearchContextType{
  filterValue: string;
  setFilterValue: (source: string) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearch: ({
    value,
    filter,
    corpusName,
    language,
    isPersistable
  }: {
    value?: string;
    filter?: string;
    corpusName?: string;
    language?: SummaryLanguage;
    isPersistable?: boolean;
  }) => void;
  onSearchFromHistory: ({
    value,
    language,
    filterValue
  }: {
    value: string,
    language: SummaryLanguage,
    filterValue: string
  }) => void;
  reset: () => void;
  isSearching: boolean;
  searchError: any;
  searchResults: OrganizedSearchResponse | undefined;
  isSummarizing: boolean;
  summarizationError: any;
  summarizationResponse: SummaryType | undefined;
  isLandingPage: boolean;
  language: SummaryLanguage;
  history: HistoryItemBase<OrganizedSearchResponse,SummaryType>[];
  clearHistory: () => void;
}

// WITH CORPUS
// export interface SearchContextType{
//   filterValue: string;
//   setFilterValue: (source: string) => void;
//   corpusNameValue : string;
//   setCorpusNameValue : (source: string) => void;
//   searchValue: string;
//   setSearchValue: (value: string) => void;
//   onSearch: ({
//     value,
//     filter,
//     corpusName,
//     language,
//     isPersistable
//   }: {
//     value?: string;
//     filter?: string;
//     corpusName?: string;
//     language?: SummaryLanguage;
//     isPersistable?: boolean;
//   }) => void;
//   onSearchFromHistory: ({
//     value,
//     language,
//     filterValue,
//     corpusNameValue
//   }: {
//     value: string,
//     language: SummaryLanguage,
//     filterValue: string,
//     corpusNameValue: string
//   }) => void;
//   reset: () => void;
//   isSearching: boolean;
//   searchError: any;
//   searchResults: OrganizedSearchResponse | undefined;
//   isSummarizing: boolean;
//   summarizationError: any;
//   summarizationResponse: SummaryType | undefined;
//   isLandingPage: boolean;
//   language: SummaryLanguage;
//   history: HistoryItemBase<OrganizedSearchResponse,SummaryType>[];
//   clearHistory: () => void;
// }

export interface Config {
  configQuestions? : string,
  configSearchTitlePre?: string,
  configSearchLinks? : string,
  configStartContentTag?: string,
  configEndContentTag?: string,
  configStartSnippetTag?: string,
  configEndSnippetTag?: string,
  configLineSeparatorTag?: string,
  configColumnSeparatorTag?: string,
  configNewLineSeparatorTag?: string,
  configNewLineTabSeparatorTag?: string,
  configStartRefTag?: string,
  configEndRefTag?: string,
  configStartButtonTag?: string,
  configEndButtonTag?: string,
  configStartFileTag?: string,
  configEndFileTag?: string,
  configDefaultLanguage?: SummaryLanguage,
  configIsEnabledFilters?: string,
  configLabelsWitValues ?: string,
  configMultipleCorpus ?: string,
  configInterfaceType?: InterfaceType

}


export interface AuthenticationContextType {
  isAuthEnabled: boolean;
  user?: User;
  isAuthenticated: boolean;
  logIn: (authToken: string | undefined | null) => void;
  logOut: () => void;
}

// WITH SOURCE
export interface ConfigContextType {
  socket: Socket,
  interfaceType: InterfaceType;
  isConfigLoaded: boolean;
  missingConfigProps: string[];
  filters: Filters,
  summary: SummaryDefaultLanguage,
  exampleQuestions: ExampleQuestions;
  searchTitlePre: string;
  searchLinks: SearchLinks;
  startContentTag: string,
  endContentTag: string,
  startSnippetTag: string,
  endSnippetTag: string,
  lineSeparatorTag: string,
  columnSeparatorTag: string,
  newLineTabSeparatorTag: string,
  newLineSeparatorTag : string,
  startRefTag: string,
  endRefTag : string,
  startButtonTag: string,
  endButtonTag: string,
  startFileTag: string,
  endFileTag: string
}

// WITH CORPUS
// export interface ConfigContextType {
//   interfaceType: InterfaceType;
//   isConfigLoaded: boolean;
//   missingConfigProps: string[];
//   filters: Filters,
//   authConfig: AuthConfig,
//   summary: SummaryDefaultLanguage,
//   exampleQuestions: ExampleQuestions;
//   searchTitlePre: string;
//   searchLinks: SearchLinks;
//   startContentTag: string,
//   endContentTag: string,
//   startSnippetTag: string,
//   endSnippetTag: string,
//   lineSeparatorTag: string,
//   columnSeparatorTag: string,
//   newLineTabSeparatorTag: string,
//   newLineSeparatorTag : string,
//   startRefTag: string,
//   endRefTag : string,
//   startButtonTag: string,
//   endButtonTag: string,
//   startFileTag: string,
//   endFileTag: string
// }
// ---------------------------------------
