import { VuiImgContainerProps } from "@customTypes/search";

import classNames from "classnames";

export const VuiImgContainer = ({ content, alt, className, onLoad }: VuiImgContainerProps) => {
  const classes = classNames(
    className
  );

  return (
    <div className={classes}>
      <br /><br /><img src={process.env.PUBLIC_URL + "img/" + (content!)} alt={alt} onLoad={onLoad} style={{"maxWidth" : "100%"}}/><br />
    </div>
  )
};