// WITH SOURCE
type Config = {
  queryStr?: string;
  numResults?: number;
  metadataFilter?: string;
  charsBefore?: number;
  charsAfter?:number;
  sentencesBefore?:number;
  sentencesAfter?: number;
};

// WITH CORPUS
// type Config = {
//   queryStr?: string;
//   numResults?: number;
//   metadataFilter?: string;
//   corpusName?: string;
//   charsBefore?: number;
//   charsAfter?:number;
//   sentencesBefore?:number;
//   sentencesAfter?: number;
// };

// WITH SOURCE
export const sendSearchRequest = async ({
  queryStr,
  numResults,
  metadataFilter,
  charsBefore,
  charsAfter,
  sentencesBefore,
  sentencesAfter,
}: Config) => {

  return fetch(`/search/`, {
    method: "POST",
    body: JSON.stringify({
      query: queryStr,
      numResults: numResults,
      metadataFilter: metadataFilter,
      charsBefore: charsBefore,
      charsAfter: charsAfter,
      sentencesBefore: sentencesBefore,
      sentencesAfter: sentencesAfter,
    }),
    headers: {
    "Content-type": "application/json; charset=UTF-8"
    }
  })
  .then(result => {
    return result.json()
  });

};


// WITH CORPUS
// export const sendSearchRequest = async ({
//   queryStr,
//   numResults,
//   metadataFilter,
//   corpusName,
//   charsBefore,
//   charsAfter,
//   sentencesBefore,
//   sentencesAfter,
// }: Config) => {

//   return fetch(`/search/`, {
//     method: "POST",
//     body: JSON.stringify({
//       query: queryStr,
//       numResults: numResults,
//       metadataFilter: metadataFilter,
//       corpusName: corpusName,
//       charsBefore: charsBefore,
//       charsAfter: charsAfter,
//       sentencesBefore: sentencesBefore,
//       sentencesAfter: sentencesAfter,
//     }),
//     headers: {
//     "Content-type": "application/json; charset=UTF-8"
//     }
//   })
//   .then(result => {
//     return result.json()
//   });
// };