import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiPrompt,
  VuiSpacer,
  VuiTextColor,
  VuiText,
} from "@adventera/vectara-ui";
import "./exampleQuestions.scss";
import { BiMessageEdit } from "react-icons/bi";
import { VuiIcon } from "@ui/components/icon/Icon";
import { useSearchContext } from "@services/context/SearchContext";
import { useConfigContext } from "@services/context/ConfigurationContext";

export const ExampleQuestions = () => {
  const { exampleQuestions } = useConfigContext();
  const { onSearch } = useSearchContext();
  const hasExampleQuestions = exampleQuestions.length > 0;

  if (!hasExampleQuestions) return null;

  return (
    <div>
      <VuiSpacer size="s" />
      <VuiFlexContainer spacing="xs" alignItems="center">
        <VuiFlexItem>
          {/* LOGO_QUESTION */}
          <VuiIcon  iconElement={BiMessageEdit} size={20} color="red"/>
        </VuiFlexItem>
        <VuiFlexItem>
          <VuiText size="s">
            <p>
              <VuiTextColor color="subdued">
                Poser votre question au dessus ou essayer avec une de celles-ci
              </VuiTextColor>
            </p>
          </VuiText>
        </VuiFlexItem>
      </VuiFlexContainer>

      <VuiSpacer size="m" />

      <VuiFlexContainer spacing="m" wrap className="promptList">
        {exampleQuestions.map((exampleQuestion) => (
          <VuiFlexItem grow={1} key={exampleQuestion}>
            <VuiPrompt
              key={exampleQuestion}
              className="prompt"
              onClick={() => {
                onSearch({ value: exampleQuestion });
              }}
            >
              <p>{exampleQuestion}</p>
            </VuiPrompt>
          </VuiFlexItem>
        ))}
      </VuiFlexContainer>
    </div>
  );
};
