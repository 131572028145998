// OK

import { SummaryType , SummaryLanguage, HistoryItem, OrganizedSearchResponse} from "@customTypes/search";

// eslint-disable-next-line
const HISTORY_KEY = `${location.hostname}:searchHistory`;

// WITH SOURCE
export const addHistoryItem = (
  {
    query,
    language,
    filterValue,
    searchResponse,
    summarizationResponse
  }: { query: string; language: SummaryLanguage,filterValue:string,searchResponse? :OrganizedSearchResponse, summarizationResponse? : SummaryType},
  history: HistoryItem[]
) => {
  const date = new Intl.DateTimeFormat(language, {
    dateStyle: "medium",
    timeStyle: "medium",
  }).format(new Date());
  const newHistoryItem = { query, language, filterValue, date, searchResponse, summarizationResponse, hidden: false };

  const newHistory = [
    newHistoryItem,
    ...history.filter((item) => (item.query !== query || item.language !==language || item.filterValue !== filterValue)),
  ];
  persistHistory(newHistory);
  return newHistory;
};


// WITH CORPUS 
// export const addHistoryItem = (
//   {
//     query,
//     language,
//     filterValue,
//     corpusNameValue,
//     searchResponse,
//     summarizationResponse
//   }: { query: string; language: SummaryLanguage,filterValue:string, corpusNameValue: string,searchResponse? :OrganizedSearchResponse, summarizationResponse? : SummaryType},
//   history: HistoryItem[]
// ) => {
//   const date = new Intl.DateTimeFormat(language, {
//     dateStyle: "medium",
//     timeStyle: "medium",
//   }).format(new Date());
//   const newHistoryItem = { query, language, filterValue, corpusNameValue, date, searchResponse, summarizationResponse, hidden: false };

//   const newHistory = [
//     newHistoryItem,
//     ...history.filter((item) => (item.query !== query || item.language !==language || item.filterValue !== filterValue || item.corpusNameValue !== corpusNameValue)),
//   ];
//   persistHistory(newHistory);
//   return newHistory;
// };

export const makeHistoryHidden = (history: HistoryItem[]) => {
  const newHistory = history.map(historyItem => ({ ...historyItem, hidden: true }));
  persistHistory(newHistory);
  return newHistory;
}

export const retrieveHistory = () => {
  const serializedHistory = sessionStorage.getItem(HISTORY_KEY);
  if (!serializedHistory) return [];
  return JSON.parse(serializedHistory);
};

export const persistHistory = (history: HistoryItem[]) => {
  const serializedHistory = JSON.stringify(history);
  sessionStorage.setItem(HISTORY_KEY, serializedHistory);
};
