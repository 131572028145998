import * as React from 'react';

import { Summary } from '@components/summary/Summary';
import { SearchSummaryProps } from '@customTypes/search';
import { VuiCallout,
  VuiFlexContainer,
  VuiFlexItem,
  VuiSpinner,
  VuiTitle,
  VuiSpacer
} from '@adventera/vectara-ui';


export const SearchSummary = ({
  isSummarizing,
  summarizationError,
  summary
}: SearchSummaryProps)  => {

  let content;

  if (isSummarizing) {
    // Loading indicator.
    content = (
      <VuiFlexContainer alignItems="center">
        <VuiFlexItem>
          <VuiSpinner size="xs" />
        </VuiFlexItem>

        <VuiFlexItem>
          <VuiTitle size="xs" align="center">
            <h2>Génération du résumé</h2>
          </VuiTitle>
        </VuiFlexItem>
      </VuiFlexContainer>
    );
  }
  else if (summarizationError) {
    // Log for diagnostics.
    console.error(summarizationError);
    content = (
      <VuiCallout headingElement="h3" title="Il y a eu une erreur lors de la génération du résumé" color="danger">
      </VuiCallout>
    );
  }

  else {
    if (summary) {
      content = (
        <>
          <VuiTitle size="xxs">
            <h2>
              <strong>Résumé</strong>
            </h2>
          </VuiTitle>

          <VuiSpacer size="s" />

          <Summary
            summary={summary}
          />
        </>
      )
    }
  }

  return (
    <>
      {content}
    </>

  );
}
export default SearchSummary;