import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiTextColor,
} from "@adventera/vectara-ui";
import { BsGlobe2, BsFillTelephoneFill, } from "react-icons/bs";
import {IoMail} from "react-icons/io5";
import { VuiIcon } from "@ui/components/icon/Icon";
import "./appFooter.scss";

// FOOTER : tout le fichier
export const AppFooter = () => {

  return (
    <VuiFlexContainer 
      justifyContent="center" 
      alignItems="stretch"
      direction="column"
      className="appFooter"
    >
      <VuiFlexItem grow={1}>
        <VuiFlexContainer
        justifyContent="center" 
        alignItems="center"
        >
          <VuiFlexItem>
            <VuiTextColor color="subdued">
              <p>Numy est une solution développée par Adventera</p>
            </VuiTextColor>
          </VuiFlexItem>
        </VuiFlexContainer>
      </VuiFlexItem>

      <VuiFlexItem grow={1}>
        <VuiFlexContainer
        justifyContent="spaceAround" 
        alignItems="center"
        >
          <VuiFlexContainer
          justifyContent="center"
          alignItems="center"
          >
            <VuiFlexItem>
              <VuiIcon  iconElement={BsGlobe2} size={20} color="red"/>
            </VuiFlexItem>
            <VuiFlexItem>
              <VuiTextColor color="subdued">
                <a href="https://adventera.fr" target="_blank" className="footerLink">https://adventera.fr/</a>
              </VuiTextColor>
            </VuiFlexItem>
          </VuiFlexContainer>

          <VuiFlexContainer
          justifyContent="center"
          alignItems="center"
          >
            <VuiFlexItem>
              <VuiIcon  iconElement={BsFillTelephoneFill} size={20} color="red"/>
            </VuiFlexItem>
            <VuiFlexItem>
              <VuiTextColor color="subdued">
                <p>+ 33 (0) 5 57 35 73 73</p>
              </VuiTextColor>
            </VuiFlexItem>
          </VuiFlexContainer>

          <VuiFlexContainer
          justifyContent="center"
          alignItems="center"
          >
            <VuiFlexItem>
              <VuiIcon  iconElement={IoMail} size={20} color="red"/>
            </VuiFlexItem>
            <VuiFlexItem>
              <VuiTextColor color="subdued">
                <a href="mailto:contact@adventera.fr" className="footerLink">contact@adventera.fr</a>
              </VuiTextColor>
            </VuiFlexItem>
          </VuiFlexContainer>
        </VuiFlexContainer>
      </VuiFlexItem>
    </VuiFlexContainer>
  );
};
