import {
  VuiCallout,
  VuiFlexContainer,
  VuiFlexItem,
  VuiSpinner,
  VuiTitle,
  VuiSpacer,
} from "@adventera/vectara-ui";
import { SearchResultList } from "@components/searchResultList/SearchResultList";
import { SearchResultsProps } from "@customTypes/search";
import { useConfigContext } from "@services/context/ConfigurationContext";


export const SearchResults = ({
  isSearching,
  searchError,
  results
}: SearchResultsProps) => {

  const { interfaceType} = useConfigContext();
  
  let content;
  if (isSearching) {
    // Loading indicator.
    content = (
      <VuiFlexContainer alignItems="center">
        <VuiFlexItem>
          <VuiSpinner size="xs" />
        </VuiFlexItem>

        <VuiFlexItem grow={false}>
          <VuiTitle size="xs" align="center">
            <h2>Recherche des références</h2>
          </VuiTitle>
        </VuiFlexItem>
      </VuiFlexContainer>
    );
  } else if (searchError) {
    // Log for diagnostics.
    console.error(searchError);
    content = (
      <VuiCallout
        title="Il y a eu une erreur lors de la récupération des résultats"
        color="danger"
        headingElement="h2"
      >
        {/* {searchError.message && (
          <VuiText>
            <p>
              <VuiTextColor color="danger">{searchError.message}</VuiTextColor>
            </p>
          </VuiText>
        )}
        {searchError.response?.data?.message && (
          <VuiText>
            <p>
              <VuiTextColor color="danger">
                {searchError.response.data.message}
              </VuiTextColor>
            </p>
          </VuiText>
        )} */}
      </VuiCallout>
    );
  } else if (results) {
    content = (
      <>
      {
        interfaceType == "searchEngine" &&
        <>
          <VuiTitle size="xxs">
            <h2>
              <strong>Résultats</strong>
            </h2>
          </VuiTitle>

          <VuiSpacer size="s" />
        </>
      }
      
      <SearchResultList
        results={results}
      />
      </>
    );
  } else if(interfaceType.startsWith("chatbot")){
    content=(
      <>
      </>
    )
    
  }

  return (
    <>
      {content}
    </>
  );
};
