import * as React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { 
  BodyProps, 
  OrganizedRow, 
  Paragraphe, 
  RowsProps, 
  ConfigContextType 
} from "@customTypes/search"

import AllTextsWithContents from '../allTextsWithContents/AllTextsWithContents';

function countOccurrences(string : string, substring: string) {
  const regex = new RegExp(substring, 'g');
  const matches = string.match(regex);
  return matches ? matches.length : 0;
}

function getTextsAndContentsTabs(text: string, config : ConfigContextType, verb : boolean = false) {

  const START_CONTENT_TAG = config.startContentTag
  const END_CONTENT_TAG = config.endContentTag
  const START_BUTTON_TAG = config.startButtonTag
  const START_FILE_TAG = config.startFileTag

  if (text.length === 0) {
    return [[''], []]
  }

  var idxsStartContent = getIndexesOfElemInString(text, START_CONTENT_TAG)
  var idxsEndContent = getIndexesOfElemInString(text, END_CONTENT_TAG, END_CONTENT_TAG.length)
  if (idxsStartContent.length === 0) {
    return [[text], []]
  }

  var textParts = []
  var contents = []
  var matchingIdxs = []

  for (let i = 0; i < idxsStartContent.length; i++) {

    let idxStart = idxsStartContent[i]
    let idxEnd = idxsEndContent[i]

    if ((matchingIdxs.length !== 0 && idxStart >= matchingIdxs[matchingIdxs.length - 1][1]) || matchingIdxs.length === 0) {

      if (countOccurrences(text.substring(idxStart, idxEnd), START_CONTENT_TAG) === 1) {
        matchingIdxs.push([idxStart, idxEnd])
      }

      else {
        let nbSousContent : number= 1
        while (countOccurrences(text.substring(idxStart, idxEnd), START_CONTENT_TAG) === nbSousContent + 1) {
          idxEnd = idxsEndContent[i + nbSousContent]
          nbSousContent += 1
        }
        matchingIdxs.push([idxStart, idxEnd])
      }
    }
  }

  if (matchingIdxs.length !== 0) {
    textParts.push(text.substring(0, matchingIdxs[0][0]))
    for (let i = 0; i < matchingIdxs.length; i++) {
      let contentInit = text.substring(matchingIdxs[i][0] + START_CONTENT_TAG.length, matchingIdxs[i][1] - END_CONTENT_TAG.length)
      let content = ""
      // Le contenu est une image
      if (contentInit.slice(-4) === ".png") {
        let idxStartName = contentInit.lastIndexOf('/') + 1
        let imgName = contentInit.substring(idxStartName, contentInit.length)
        if (imgName.substring(0, 6) === "bouton") {
          content = START_BUTTON_TAG + contentInit.substring(START_FILE_TAG.length, contentInit.length)
        }
        else {
          content = contentInit
        }
      }
      else {
        content = contentInit
      }
      contents.push(content)

      if (i !== matchingIdxs.length - 1) {
        textParts.push(text.substring(matchingIdxs[i][1], matchingIdxs[i + 1][0]))
      }
      else {
        if (matchingIdxs[i][1] !== -1) {
          if (text.substring(matchingIdxs[i][1], text.length).trim().length !== 0) {
            textParts.push(text.substring(matchingIdxs[i][1], text.length))
          }
        }

      }
    }
  }
  return [textParts, contents]

}

function getParagraphesTab(paragraphesTab : Array<string>, config: ConfigContextType){

  let paragraphesContent = []
  let inContent = false
  for (let i = 0; i < paragraphesTab.length; i++) {
    if (i > 0) {
      if (countOccurrences(paragraphesTab[i - 1], config.startSnippetTag) > countOccurrences(paragraphesTab[i - 1], config.endSnippetTag)) {
        inContent = true
      }
    }
    if (countOccurrences(paragraphesTab[i], config.startSnippetTag) < countOccurrences(paragraphesTab[i], config.endSnippetTag)) {
      inContent = false
    }
    let textsAndContentsTab = getTextsAndContentsTabs(paragraphesTab[i], config)
    let content : Paragraphe = {
      "texts" : textsAndContentsTab[0],
      "contents" : textsAndContentsTab[1],
      "inContent" :  inContent
    }
    content["texts"] = textsAndContentsTab[0]
    content["contents"] = textsAndContentsTab[1]
    content["inContent"] = inContent
    paragraphesContent.push(content)
  }
  return paragraphesContent
}


function getIndexesOfElemInString(string: string, elem: string, toAdd = 0) {
  var indexes = [];
  var index = string.indexOf(elem);

  while (index !== -1) {
    indexes.push(index + toAdd);
    index = string.indexOf(elem, index + 1);
  }
  return indexes;
}

function getOrganizedTabContent(content: string, nbColumns: number, config : ConfigContextType): Array<OrganizedRow> {

  const rows = (content.split(config.lineSeparatorTag)).filter((element) => element.trim());
  var organizedRows: Array<OrganizedRow> = [];

  for (var idxRow = 0; idxRow < rows.length; idxRow++) {

    if (!rows[idxRow]) {
      continue;
    }

    let organizedRow: OrganizedRow = []
    let columnsTab = (rows[idxRow].split(config.columnSeparatorTag)).filter((element) => element.trim());
    for (let i = columnsTab.length; i < nbColumns; i++) {
      columnsTab.unshift('');
    }

    // Ici, dans columnsTab, on a bien tout le contenu encore 


    for (let idxColumn = 0; idxColumn < columnsTab.length; idxColumn++) {

      // Transformer le texte de la "case" en paragraphes de texts et contents
      let paragraphesTab = getParagraphesTab(columnsTab[idxColumn].split(config.newLineTabSeparatorTag).filter((element) => element.trim()), config)
      organizedRow.push(paragraphesTab)
    }
    organizedRows.push(organizedRow)
  }
  return organizedRows
}


function Rows({ content, isStrong, nbColumn, config }: RowsProps) {

  let organizedRows = getOrganizedTabContent(content, nbColumn, config)

  return (
    <React.Fragment>
      {organizedRows.map((row, idxRow) => (
        <TableRow key={"row_"+idxRow} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {
            isStrong &&
            row.map((column, idxColumn)=>(
              <TableCell key={"strongColumn_"+idxColumn} align="left">
                <strong>
                  <AllTextsWithContents
                    paragraphes={column}
                    columnNames={[]}
                    textSize="s"
                  />
                </strong>
              </TableCell>
            ))
          }
          {
            !isStrong &&
            row.map((column, idxColumn)=>(
              <TableCell key={"column_"+idxColumn} align="left">
                <AllTextsWithContents
                  paragraphes={column}
                  columnNames={[]}
                  textSize="s"
                />
              </TableCell>
            ))
          }
          
        </TableRow>
      ))}
    </React.Fragment>
  );
}


function Body({ content, nbColumns, config }: BodyProps) {

  let organizedRows = getOrganizedTabContent(content, nbColumns, config)

  return (
    <React.Fragment>
      {organizedRows.map((row, idxRow) => (
        <TableRow key={"row_"+idxRow} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          {
            row.map((column, idxColumn) => (
              <TableCell key={"column_"+idxColumn} align="left">
                <div className='table_cell'>
                  <AllTextsWithContents
                    paragraphes={column}
                    columnNames={[]}
                    textSize="s"
                  />
                </div>
              </TableCell>
            ))
          }
        </TableRow>
      ))}
    </React.Fragment>
  );
}
export { Body, Rows };