
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useConfigContext } from "./ConfigurationContext";
import {
  addHistoryItem,
  retrieveHistory,
  makeHistoryHidden
} from "./history";
import { sendSearchRequest } from "@services/sendSearchRequest";
import { sensResumeRequest } from "@services/sendResumeRequest";
import {
  SummaryLanguage, 
  SummaryType,
  SearchContextType, 
  SearchContextProviderProps,
  HistoryItem,
  OrganizedSearchResponse
} from "@customTypes/search";

const SearchContext = createContext<SearchContextType | undefined>(undefined);

const getQueryParam = (urlParams: URLSearchParams, key: string) => {
  const value = urlParams.get(key);
  if (value) return decodeURIComponent(value);
  return undefined;
};

let searchCount = 0;

export const SearchContextProvider = ({ children }: SearchContextProviderProps) => {
  const { socket, isConfigLoaded, summary: { defaultLanguage } } = useConfigContext();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState("");
  // WITH CORPUS
  // const [corpusNameValue, setCorpusNameValue] = useState("")

  const [searchParams, setSearchParams] = useSearchParams();
  const [languageValue, setLanguageValue] = useState<SummaryLanguage>(defaultLanguage as SummaryLanguage);
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState<any>();
  const [searchResponse, setSearchResponse] = useState<OrganizedSearchResponse>();
  const [index, setIndex] = useState<number>(0);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [summarizationError, setSummarizationError] = useState<any>();
  const [summarizationResponse, setSummarizationResponse] = useState<SummaryType>();
  const [historyItem, setHistoryItem] = useState<HistoryItem>();
  const [isLandingPage, setIsLandingPage] = useState<boolean>(false);

  const [conversationId, setConversationId] = useState<string>("");


  useEffect(()=>{
    socket.on('show_results', (data) => {
      data = JSON.parse(data)
      let searchResp = data.searchResponse
      let idx = data.index
      if(searchResp != searchResponse){
        setSearchResponse(searchResp);
      }
      if(index != idx ){
        setIndex(idx);
        if (idx !== 0) {
          const id = `searchResult_${idx}`;
          const searchResultElement = document.getElementById(id);
      
          if (searchResultElement) {
            const appHeaderElement = document.getElementById("appHeader");
            const yOffset = -appHeaderElement?.offsetHeight!; 
            const scrollPosition = window.scrollY 
            const y = searchResultElement.getBoundingClientRect().top+ scrollPosition + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        }
        else {
          window.scrollTo({top: 0,behavior: 'smooth'})
        }
      }
    });
    
    if (index !== 0) {
      const id = `searchResult_${index}`;
      const searchResultElement = document.getElementById(id);
  
      if (searchResultElement) {
        const appHeaderElement = document.getElementById("appHeader");
        const yOffset = -appHeaderElement?.offsetHeight!; 
        const scrollPosition = window.scrollY 
        const y = searchResultElement.getBoundingClientRect().top+ scrollPosition + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
    else {
      window.scrollTo({top: 0,behavior: 'smooth'});
    }
  }, [socket, searchResponse, index])

  useEffect(() => {
    setHistory(retrieveHistory());
  }, []);

  useEffect(() => {
    if(historyItem){
      setHistory(addHistoryItem(historyItem, history))
    }
  }, [historyItem])

  // WITH SOURCE
  const getHistoryResponses = (value: string, language: SummaryLanguage, filterValue: string) => {
    const historyObject = history.find(
      (item) => item.query === value && item.language === language && item.filterValue === filterValue
    );
    return {historySearchResponse : historyObject?.searchResponse, historySummarizationresponse : historyObject?.summarizationResponse}
  }

  // WITH CORPUS
  // const getHistoryResponses = (value: string, language: SummaryLanguage, filterValue: string, corpusNameValue: string) => {
  //   const historyObject = history.find(
  //     (item) => item.query === value && item.language === language && item.filterValue === filterValue && item.corpusNameValue === corpusNameValue
  //   );
  //   return {historySearchResponse : historyObject?.searchResponse, historySummarizationresponse : historyObject?.summarizationResponse}
  // }
  
  // Use the browser back and forward buttons to traverse history
  // of searches, and bookmark or share the URL.
  useEffect(() => {
    if (!isConfigLoaded) return;

    const urlParams = new URLSearchParams(searchParams);

    // WITH SOURCE
    if(isSearching){
      setIsLandingPage(false)
    }

    // WITH CORPUS
    // if(isSearching){
    //   setIsLandingPage(false)
    // }

    else {
      // WITH SOURCE
      if(getQueryParam(urlParams, "query")){
        setIsLandingPage(false)
        onSearchFromHistory({
          value: getQueryParam(urlParams, "query") ?? "",
          filterValue: getQueryParam(urlParams, "filter") ?? "",
          language: getQueryParam(urlParams, "language") as
            | SummaryLanguage
            | undefined ?? "fra"
        })
      } 
      
      // WITH CORPUS
      // if(getQueryParam(urlParams, "query")){
      //   setIsLandingPage(false)
      //   onSearchFromHistory({
      //     value: getQueryParam(urlParams, "query") ?? "",
      //     filterValue: getQueryParam(urlParams, "filter") ?? "",
      //     corpusNameValue: getQueryParam(urlParams, "corpusName") ?? "",
      //     language: getQueryParam(urlParams, "language") as
      //       | SummaryLanguage
      //       | undefined ?? "fra"
      //   })
      // }   
      else {
        // WITH SOURCE
        if(searchResponse || searchError){
          setIsLandingPage(true);
          setSearchValue("");
          setFilterValue("");
          setSearchResponse(undefined)
          setSearchError(undefined)
        }

        // WITH CORPUS
        // if(searchResponse || searchError){
        //   setIsLandingPage(true);
        //   setSearchValue("");
        //   setFilterValue("");
        //   setCorpusNameValue("");
        //   setSearchResponse(undefined)
        //   setSearchError(undefined)
        // }
      }   
    }
   
  }, [isConfigLoaded, searchParams]);

  const clearHistory = () => {
    setHistory(makeHistoryHidden(history));
  };

  // WITH SOURCE
  const onSearchFromHistory = ({
    value,
    language,
    filterValue,
  }: {
    value: string;
    language : SummaryLanguage,
    filterValue: string,
  }) => {
    setIsLandingPage(false);
    setSearchValue(value);
    setFilterValue(filterValue);
    setLanguageValue(language);

    const {historySearchResponse, historySummarizationresponse} = getHistoryResponses(value, language, filterValue)

    if (value.trim()) {


      setIsSearching(true);
      setIsSummarizing(true);


      // Les réponses
      setIsSearching(false);
      setSearchError(undefined);
      setSearchResponse(historySearchResponse);


      //Le résumé
      setIsSummarizing(false);
      setSummarizationError(undefined);
      setSummarizationResponse(historySummarizationresponse);    

    }
  }

  // WITH CORPUS
  // const onSearchFromHistory = ({
  //   value,
  //   language,
  //   filterValue,
  //   corpusNameValue
  // }: {
  //   value: string;
  //   language : SummaryLanguage,
  //   filterValue: string,
  //   corpusNameValue: string
  // }) => {
  //   setIsLandingPage(false);
  //   setSearchValue(value);
  //   setFilterValue(filterValue);
  //   setCorpusNameValue(corpusNameValue);
  //   setLanguageValue(language);

  //   const {historySearchResponse, historySummarizationresponse} = getHistoryResponses(value, language, filterValue, corpusNameValue)

  //   if (value.trim()) {


  //     setIsSearching(true);
  //     setIsSummarizing(true);


  //     // Les réponses
  //     setIsSearching(false);
  //     setSearchError(undefined);
  //     setSearchResponse(historySearchResponse);


  //     //Le résumé
  //     setIsSummarizing(false);
  //     setSummarizationError(undefined);
  //     setSummarizationResponse(historySummarizationresponse);    

  //   }
  // }


  // WITH SOURCE
  const onSearch = async ({
    value = searchValue,
    filter = filterValue,
    language =  languageValue,
    isPersistable = true
  }: {
    value?: string;
    filter?: string;
    language?: SummaryLanguage;
    isPersistable?: boolean
  }) => {
    const searchId = ++searchCount;

    setSearchValue(value);
    setFilterValue(filter);
    setLanguageValue(language);

    if (value?.trim()) {
      // Object to Save to history.
      var newHistoryItem : HistoryItem = {query: value, language, filterValue}


      // Persist to URL, only if the search executes. This way the prior
      // search that was persisted remains in the URL if the search doesn't execute.
      if (isPersistable) {
        setSearchParams(
          new URLSearchParams(
            `?query=${encodeURIComponent(value)}&filter=${encodeURIComponent(
              filter
            )}&language=${encodeURIComponent(language)}`
          )
        );
      }

      // First call - only search results - should come back quicky while we wait for summarization
      setIsSearching(true);
      setIsSummarizing(true);

      try {
        const response = await sendSearchRequest({
          queryStr: value,
          metadataFilter: filter ? filter : '',
        });
        // If we send multiple requests in rapid succession, we only want to
        // display the results of the most recent request.
        if (searchId === searchCount) {
          setIsSearching(false);
          setSearchError(undefined);
          setSearchResponse(response);
          newHistoryItem.searchResponse = response;
        }
      } catch (error) {
        setIsSearching(false);
        setSearchError(error);
        setSearchResponse(undefined);
        newHistoryItem.searchResponse = undefined;
      }
      // Second call - search and summarize; this may take a while to return results
      try {
        // Ancien
        // const response = await sendSearchRequest({
        //   queryStr: value,
        //   metadataFilter : filter ? filter : '',
        //   includeSummary: true,
        //   language : language
        // });
        const response = await sensResumeRequest({
          queryStr: value,
          metadataFilter: filter ? filter : '',
          language: language,
          conversationId: conversationId
        });

        // If we send multiple requests in rapid succession, we only want to
        // display the results of the most recent request.
        if (searchId === searchCount) {
          setIsSummarizing(false);
          setSummarizationError(undefined);
          setSummarizationResponse(response);
          newHistoryItem.summarizationResponse = response;
          setHistoryItem(newHistoryItem);
          setConversationId(response.conversationId)
        }
      } catch (error) {
        setIsSummarizing(false);
        setSummarizationError(error);
        setSummarizationResponse(undefined);
      }
      
    } else {
      // Persist to URL.
      if (isPersistable) setSearchParams(new URLSearchParams(""));

      setSearchResponse(undefined);
      setSummarizationResponse(undefined);
      setIsSearching(false);
      setIsSummarizing(false);
    }
  };


  // WITH CORPUS 
  // const onSearch = async ({
  //   value = searchValue,
  //   filter = filterValue,
  //   corpusName= corpusNameValue,
  //   language =  languageValue,
  //   isPersistable = true
  // }: {
  //   value?: string;
  //   filter?: string;
  //   corpusName?: string;
  //   language?: SummaryLanguage;
  //   isPersistable?: boolean
  // }) => {
  //   const searchId = ++searchCount;

  //   setSearchValue(value);
  //   setFilterValue(filter);
  //   setCorpusNameValue(corpusName);
  //   setLanguageValue(language);

  //   if (value?.trim()) {
  //     // Object to Save to history.
  //     var newHistoryItem : HistoryItem = {query: value, language, filterValue, corpusNameValue}


  //     // Persist to URL, only if the search executes. This way the prior
  //     // search that was persisted remains in the URL if the search doesn't execute.
  //     if (isPersistable) {
  //       setSearchParams(
  //         new URLSearchParams(
  //           `?query=${encodeURIComponent(value)}&filter=${encodeURIComponent(
  //             filter
  //           )}&corpusName=${encodeURIComponent(corpusName)}&language=${encodeURIComponent(language)}`
  //         )
  //       );
  //     }

  //     // First call - only search results - should come back quicky while we wait for summarization
  //     setIsSearching(true);
  //     setIsSummarizing(true);

  //     try {
  //        const response = await sendSearchRequest({
  //          queryStr: value,
  //          metadataFilter: filter ? filter : '',
  //          corpusName: corpusName,
  //        });
  //       // If we send multiple requests in rapid succession, we only want to
  //       // display the results of the most recent request.
  //       if (searchId === searchCount) {
  //         setIsSearching(false);
  //         setSearchError(undefined);
  //         setSearchResponse(response);
  //         newHistoryItem.searchResponse = response;
  //       }
  //     } catch (error) {
  //       setIsSearching(false);
  //       setSearchError(error);
  //       setSearchResponse(undefined);
  //       newHistoryItem.searchResponse = undefined;
  //     }
  //     // Second call - search and summarize; this may take a while to return results
  //     try {
  //      const response = await sensResumeRequest({
  //        queryStr: value,
  //        metadataFilter: filter ? filter : '',
  //        corpusName: corpusName,
  //        language: language,
  //        conversationId: conversationId
  //      });

  //       // If we send multiple requests in rapid succession, we only want to
  //       // display the results of the most recent request.
  //       if (searchId === searchCount) {
  //         setIsSummarizing(false);
  //         setSummarizationError(undefined);
  //         setSummarizationResponse(response);
  //         newHistoryItem.summarizationResponse = response;
  //         setHistoryItem(newHistoryItem);
  //         setConversationId(response.conversationId)
  //       }
  //     } catch (error) {
  //       setIsSummarizing(false);
  //       setSummarizationError(error);
  //       setSummarizationResponse(undefined);
  //     }
      
  //   } else {
  //     // Persist to URL.
  //     if (isPersistable) setSearchParams(new URLSearchParams(""));

  //     setSearchResponse(undefined);
  //     setSummarizationResponse(undefined);
  //     setIsSearching(false);
  //     setIsSummarizing(false);
  //   }
  // };

  const reset = () => {
    // Specifically don't reset language because that's more of a
    // user preference.
    // WITH CORPUS
    // onSearch({ value: "", filter : "", corpusName: ""});
    // WITH SOURCE
    onSearch({ value: "", filter : ""});
  };

  return (
    // WITH SOURCE
    <SearchContext.Provider
      value={{
        filterValue,
        setFilterValue,
        searchValue,
        setSearchValue,
        onSearch,
        onSearchFromHistory,
        reset,
        isSearching,
        searchError,
        searchResults: searchResponse,
        isSummarizing,
        summarizationError,
        summarizationResponse,
        isLandingPage,
        language: languageValue,
        history,
        clearHistory
      }}
    >
      {children}
    </SearchContext.Provider>
  
    // WITH CORPUS
    // <SearchContext.Provider
    //   value={{
    //     filterValue,
    //     setFilterValue,
    //     corpusNameValue, 
    //     setCorpusNameValue,
    //     searchValue,
    //     setSearchValue,
    //     onSearch,
    //     onSearchFromHistory,
    //     reset,
    //     isSearching,
    //     searchError,
    //     searchResults: searchResponse,
    //     isSummarizing,
    //     summarizationError,
    //     summarizationResponse,
    //     isLandingPage,
    //     language: languageValue,
    //     history,
    //     clearHistory
    //   }}
    // >
    //   {children}
    // </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider"
    );
  }
  return context;
};
